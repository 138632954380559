import * as React from 'react';
import { useState } from 'react';

import type { HeadProps, PageProps } from 'gatsby';

import { Link } from 'stile-shared/src/components/2020/common/Link';
import {
  P,
  PageHeading,
  Section,
  Spacer,
} from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Sidebar } from 'stile-shared/src/components/2020/layout/Sidebar';
import { CONTACT } from 'stile-shared/src/constants';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { TrialFormUs } from 'components/common/TrialFormUs';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Get in touch"
      description="Getting Stile at your school."
      slug={props.location.pathname}
    />
  );
}

function SetUpTrialPage(props: PageProps) {
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  return (
    <PageLayout {...props}>
      <Section>
        <PageHeading>Your Stile journey awaits</PageHeading>
        <Spacer />
        <Sidebar side="right" sideWidth="480px" space={theme.space.xl}>
          <Column>
            <TrialFormUs onSubmissionSuccess={setSubmissionSuccess} />
          </Column>

          {!submissionSuccess && (
            <Column>
              <P bold>Want to try Stile for yourself?</P>
              <P>
                We’ve got good news – you can trial any of Stile’s digital units for free. Just
                register your details in this form, and we’ll be in touch right away to get you
                started.
              </P>
              <P bold>Exploring options for an adoption?</P>
              <P>
                We believe it’s crucial to evaluate every teaching resource thoroughly before making
                a long-term adoption. That’s why we’ve designed a fully supported{' '}
                <Link url="/why-choose-stile/oregon-pilot/">pilot process</Link>, which we customize
                to your unique needs. The pilot covers everything from teacher training to final
                assessment, combining digital, print and hands-on labs for a complete learning
                experience. To find out (pi)lots more, submit this form to meet with one of our
                Curriculum Specialists.
              </P>
              <P>
                Or, if you’d prefer to start a conversation right away, give us a call on{' '}
                <Link url={CONTACT.us.phone.url}>{CONTACT.us.phone.text}</Link>.
              </P>
              <P>We look forward to working with you.</P>
            </Column>
          )}
        </Sidebar>
      </Section>
    </PageLayout>
  );
}

export default SetUpTrialPage;
